<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"> </ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10 mt-3">
              <div class="page-title">Criar Agendamento de Corrida</div>
            </div>
          </div>
          <div [formGroup]="tripForm">
            <div class="card card-topline-green">
              <div class="form-group container">
                <div class="row">
                  <div class="col-8">
                    <label class="mt-2">Tipo de Solicitação</label>
                    <div class="row mt-4 mb-4">
                      <div class="col-sm-4 row ml-3">
                        <input
                          class="mt-1"
                          type="radio"
                          value="client"
                          name="passenger"
                          id="client"
                          (change)="changePassenger('client')"
                          formControlName="passenger"
                        />
                        <label for="client" class="ml-2" style="font-size: 16px"
                          >Cliente</label
                        >
                      </div>
                      <div class="col-sm-4 row ml-3">
                        <input
                          class="mt-1"
                          type="radio"
                          value="covenant"
                          name="passenger"
                          id="covenant"
                          (change)="changePassenger('covenant')"
                          formControlName="passenger"
                        />
                        <label
                          class="ml-2"
                          style="font-size: 16px"
                          for="covenant"
                          >Convênio</label
                        >
                      </div>
                    </div>
                    <span
                      *ngIf="
                        tripForm.controls.passenger.errors &&
                        tripForm.controls.passenger.errors.required
                      "
                      class="text-danger ml-3"
                      >* Escolha um Tipo de Solicitação</span
                    >
                  </div>
                  <div
                    class="col-4 mt-4"
                    *ngIf="tripForm.controls.passenger.value === 'covenant'"
                  >
                    <label class="mt-3"
                      >Código de Solicitação do Convênio</label
                    >
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Código de Solicitação do Convênio"
                      formControlName="covenantCode"
                    />
                    <span
                      *ngIf="
                        tripForm.controls.covenantCode.errors &&
                        tripForm.controls.covenantCode.errors.invalid
                      "
                      class="text-danger position-right"
                      >*
                      {{
                        searchClientForm.controls.covenantCode.errors.invalid
                      }}
                    </span>
                  </div>
                </div>
                <div *ngIf="tripForm.controls.passenger.value != ''">
                  <div *ngIf="messageError.length" class="container">
                    <div class="alert alert-danger" role="alert">
                      {{ messageError }}
                    </div>
                  </div>
                  <label>Dados Cadastrais</label>
                  <div [formGroup]="searchClientForm">
                    <div class="row">
                      <div class="col-sm-12 mt-1">
                        <input
                          (keyup)="getSearchUser()"
                          type="text"
                          class="form-control"
                          placeholder="Pesquisar Cliente/Convênio - por nome, CPF ou CNPJ"
                          formControlName="searchClient"
                        />
                        <span
                          *ngIf="
                            searchClientForm.controls.searchClient.errors &&
                            searchClientForm.controls.searchClient.errors
                              .invalid
                          "
                          class="text-danger position-right"
                          >*
                          {{
                            searchClientForm.controls.searchClient.errors
                              .invalid
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-sm-4">
                      <label>Nome</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Nome"
                        formControlName="name"
                      />
                    </div>
                    <div class="col-sm-3">
                      <label>Whatsapp</label>
                      <input
                        type="text"
                        class="form-control"
                        mask="+00 00 0 0000-0000"
                        placeholder="Whatsapp"
                        formControlName="whatsapp"
                      />
                    </div>
                    <div class="col-sm-3">
                      <label>Telefone</label>
                      <input
                        type="text"
                        class="form-control"
                        mask="(00) 0000-0000||(00) 0 0000-0000"
                        placeholder="Telefone"
                        formControlName="phone"
                      />
                    </div>
                    <div class="col d-flex align-items-end">
                      <app-modal-update-covenant-address-form
                        *ngIf="
                          tripForm.controls.passenger.value == 'covenant' &&
                          covenant
                        "
                        [covenant]="covenant"
                        (modalConfirm)="onCovanantUpdated($event)"
                      ></app-modal-update-covenant-address-form>

                      <app-modal-update-client-address-form
                        *ngIf="
                          tripForm.controls.passenger.value == 'client' &&
                          client
                        "
                        [client]="client"
                        (modalConfirm)="onClientUpdated($event)"
                      ></app-modal-update-client-address-form>
                    </div>
                    <div class="col mt-3">
                      <ngb-alert
                        [dismissible]="true"
                        [type]="'success'"
                        (close)="message = ''"
                        *ngIf="message"
                        >{{ message }}</ngb-alert
                      >
                    </div>
                  </div>
                  <div
                    *ngIf="
                      tripForm.controls.clientId.value != '' ||
                      tripForm.controls.covenantId.value != ''
                    "
                  >
                    <label class="mt-3">Dados da Solicitação</label>
                    <div class="row">
                      <div class="col-sm-3 row ml-3 mt-3">
                        <input
                          class="mt-1"
                          type="checkbox"
                          formControlName="scheduled"
                        />
                        <label class="ml-2" style="font-size: 16px"
                          >Agendamento?</label
                        >
                      </div>
                      <div
                        class="col-sm-3 row ml-3 mt-3"
                        *ngIf="tripForm.controls.scheduled.value"
                      >
                        <input
                          class="mt-1"
                          type="checkbox"
                          formControlName="recursive"
                          (change)="onCheckboxChangeRecursive($event)"
                        />
                        <label class="ml-2" style="font-size: 16px"
                          >Recursividade</label
                        >
                      </div>
                    </div>
                    <div class="row" *ngIf="tripForm.controls.scheduled.value">
                      <div class="col-sm-6 mt-1">
                        <label *ngIf="tripForm.controls.recursive.value"
                          >Data de Início</label
                        >
                        <label *ngIf="!tripForm.controls.recursive.value"
                          >Data</label
                        >
                        <input
                          type="date"
                          class="form-control"
                          formControlName="startDate"
                        />
                        <span
                          *ngIf="
                            tripForm.controls.startDate.errors &&
                            tripForm.controls.startDate.errors.required
                          "
                          class="text-danger position-right"
                          >Campo Obrigatório</span
                        >
                      </div>
                      <div
                        class="col-sm-6 mt-1"
                        *ngIf="tripForm.controls.recursive.value"
                      >
                        <label>Data Fim</label>
                        <input
                          type="date"
                          class="form-control"
                          formControlName="endDate"
                        />
                        <span
                          *ngIf="
                            tripForm.controls.endDate.errors &&
                            tripForm.controls.endDate.errors.required
                          "
                          class="text-danger position-right"
                          >{{ tripForm.controls.endDate.errors.required }}</span
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 mt-1">
                        <label>Hora início</label>
                        <input
                          type="time"
                          class="form-control"
                          placeholder="Hora início"
                          formControlName="starttime"
                        />
                        <span
                          *ngIf="
                            tripForm.controls.starttime.errors &&
                            tripForm.controls.starttime.errors.required
                          "
                          class="text-danger position-right"
                          >*Campo Obrigatório</span
                        >
                      </div>
                      <div class="col-sm-6 mt-1">
                        <label>Hora Fim</label>
                        <input
                          type="time"
                          class="form-control"
                          placeholder="Hora fim"
                          formControlName="endtime"
                        />
                        <span
                          *ngIf="
                            tripForm.controls.endtime.errors &&
                            tripForm.controls.endtime.errors.required
                          "
                          class="text-danger position-right"
                          >*Campo Obrigatório</span
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div
                        class="col-sm-6 row ml-3 mt-3"
                        *ngIf="tripForm.controls.recursive.value"
                      >
                        <input
                          class="mt-1"
                          type="checkbox"
                          formControlName="allDays"
                          (change)="onCheckboxChangeAllDays($event)"
                        />
                        <label class="ml-2" style="font-size: 16px"
                          >Todos os dias da semana</label
                        >
                      </div>
                    </div>
                    <div
                      class="row ml-3"
                      *ngIf="tripForm.controls.recursive.value"
                    >
                      <div class="col-sm-3 row mt-4">
                        <input
                          class="mt-1"
                          type="checkbox"
                          formControlName="sunday"
                        />
                        <label class="ml-2" style="font-size: 16px"
                          >Domingo</label
                        >
                      </div>
                      <div class="col-sm-3 row mt-4">
                        <input
                          class="mt-1"
                          type="checkbox"
                          formControlName="monday"
                        />
                        <label class="ml-2" style="font-size: 16px"
                          >Segunda</label
                        >
                      </div>
                      <div class="col-sm-3 row mt-4">
                        <input
                          class="mt-1"
                          type="checkbox"
                          formControlName="tuesday"
                        />
                        <label class="ml-2" style="font-size: 16px"
                          >Terça</label
                        >
                      </div>
                      <div class="col-sm-3 row mt-4">
                        <input
                          class="mt-1"
                          type="checkbox"
                          formControlName="wednesday"
                        />
                        <label class="ml-2" style="font-size: 16px"
                          >Quarta</label
                        >
                      </div>
                      <div class="col-sm-3 row mt-4">
                        <input
                          class="mt-1"
                          type="checkbox"
                          formControlName="thursday"
                        />
                        <label class="ml-2" style="font-size: 16px"
                          >Quinta</label
                        >
                      </div>
                      <div class="col-sm-3 row mt-4">
                        <input
                          class="mt-1"
                          type="checkbox"
                          formControlName="friday"
                        />
                        <label class="ml-2" style="font-size: 16px"
                          >Sexta</label
                        >
                      </div>
                      <div class="col-sm-3 row mt-4">
                        <input
                          class="mt-1"
                          type="checkbox"
                          formControlName="saturday"
                        />
                        <label class="ml-2" style="font-size: 16px"
                          >Sábado</label
                        >
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12">
                        <label>Valor da Corrida</label>
                        <ng-select
                          class="custom-select custom-select"
                          style="font-size: 14px"
                          (change)="selectRequestValue()"
                          formControlName="valueSolicitation"
                          [items]="requestValues"
                          bindLabel="destiny"
                          bindValue="id"
                        >
                          <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-index="index"
                            let-search="searchTerm"
                          >
                            <span>{{ item.destiny }}</span>
                          </ng-template>
                        </ng-select>
                        <span
                          *ngIf="
                            tripForm.controls.valueSolicitation.errors &&
                            tripForm.controls.valueSolicitation.errors.required
                          "
                          class="text-danger position-right"
                          >*Campo Obrigatório</span
                        >
                      </div>
                    </div>

                    <div *ngIf="tripForm.controls.valueSolicitation.value">
                      <div
                        *ngIf="tripForm.controls.passenger.value == 'covenant'"
                      >
                        <label class="mt-3 mb-4" style="font-size: 20px"
                          >Passageiros</label
                        >
                        <div class="row">
                          <div class="col">
                            <ng-select
                              class="custom-select custom-select"
                              style="font-size: 14px"
                              formControlName="collaborator"
                              [items]="collaborators"
                              bindLabel="name"
                              bindValue="id"
                              (search)="onCollaboratorSearch($event)"
                            >
                              <ng-template
                                ng-option-tmp
                                let-item="item"
                                let-index="index"
                                let-search="searchTerm"
                              >
                                <span>{{ item.name }}</span>
                              </ng-template>
                            </ng-select>
                            <span
                              *ngIf="
                                tripForm.controls.passengers.value.length ==
                                  0 &&
                                !tripForm.controls.passengers?.hasError(
                                  'no_address'
                                )
                              "
                              class="text-danger position-right"
                              >* Adicione ao menos um Passageiro</span
                            >
                            <span
                              *ngIf="
                                tripForm.controls.passengers?.hasError(
                                  'no_address'
                                )
                              "
                              class="text-danger position-right"
                              >* Passsageiro precisa ter o endereço
                              cadastrado</span
                            >
                          </div>
                          <div class="col">
                            <button
                              type="button"
                              class="btn btn-success"
                              (click)="onAddCollaborator()"
                            >
                              Adicionar
                            </button>
                            <button
                              type="button"
                              class="btn btn-info ml-3"
                              (click)="
                                collaboratorToEdit = null;
                                modalFireCondition = true
                              "
                            >
                              Cadastrar novo
                            </button>
                          </div>
                        </div>

                        <app-modal-covenant-collaborator-form
                          [modalFireCondition]="modalFireCondition"
                          [covenantId]="covenantId"
                          [collaborator]="collaboratorToEdit"
                          (modalClose)="onCloseModalCreateCollaborator($event)"
                        ></app-modal-covenant-collaborator-form>

                        <div class="table-responsive mt-3">
                          <table
                            class="table table-striped custom-table table-hover"
                            formArrayName="passengers"
                          >
                            <thead>
                              <tr>
                                <th>Colaborador</th>
                                <!-- <th>Telefone</th> -->
                                <!-- <th>Whatsapp</th> -->
                                <!-- <th>Centro de Custo</th> -->
                                <th>Principal</th>
                                <th>Quantidade de Pontos</th>
                                <th>Ações</th>
                              </tr>
                            </thead>
                            <tbody
                              *ngFor="
                                let passenger of passengers.controls;
                                let i = index
                              "
                              [formGroupName]="i"
                            >
                              <tr>
                                <td>
                                  {{ passenger.controls.name.value }}<br />
                                  {{
                                    passenger.controls.whatsapp.value
                                      | mask : "+00 00 0 0000-0000"
                                  }}
                                </td>
                                <!-- <td>
                                  {{ passenger.controls.phone.value }}
                                </td>
                                <td>
                                  {{ passenger.controls.whatsapp.value }}
                                </td>
                                <td>
                                  {{ passenger.controls.costCenter.value }}
                                </td> -->
                                <td>
                                  <input
                                    type="checkbox"
                                    formControlName="main"
                                  />
                                </td>
                                <td>
                                  <div class="col-sm-12">
                                    <ng-select
                                      class="custom-select custom-select"
                                      style="font-size: 14px"
                                      formControlName="destinyPoint"
                                      [items]="destinyPoints"
                                      bindLabel="name"
                                      bindValue="id"
                                    >
                                      <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                        let-index="index"
                                        let-search="searchTerm"
                                      >
                                        <span>{{ item.name }}</span>
                                      </ng-template>
                                    </ng-select>
                                    <span
                                      *ngIf="
                                        getPassengerFieldValidity(
                                          'destinyPoint',
                                          i
                                        )
                                      "
                                      class="text-danger position-right"
                                      >Obrigatório</span
                                    >
                                  </div>
                                </td>
                                <td>
                                  <div class="row">
                                    <a
                                      class="btn btn-primary"
                                      data-toggle="collapse"
                                      href="#collapseOriginAddress{{ i }}"
                                      role="button"
                                      aria-expanded="false"
                                      aria-controls="collapseOriginAddress"
                                    >
                                      Endereço de Origem
                                    </a>
                                    <a
                                      class="btn btn-primary"
                                      data-toggle="collapse"
                                      href="#collapseDestinyAddress{{ i }}"
                                      role="button"
                                      aria-expanded="false"
                                      aria-controls="collapseDestinyAddress"
                                    >
                                      Endereço de Destino
                                      <span
                                        *ngIf="
                                          getPassengerAddressValidity(
                                            'destinyAddress',
                                            'zipcode',
                                            i
                                          )
                                        "
                                        class="text-danger position-right ml-2"
                                        >Obrigatório</span
                                      >
                                    </a>
                                    <button
                                      (click)="removeCollaborator(i)"
                                      class="btn btn-danger btn-xs"
                                    >
                                      <i class="fa fa-trash-o"></i>
                                    </button>
                                    <button
                                      (click)="editCollaborator(passenger)"
                                      class="btn btn-warning btn-xs"
                                    >
                                      <i class="fa fa-pencil"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              <tr
                                class="collapse"
                                id="collapseOriginAddress{{ i }}"
                              >
                                <td colspan="6" class="hiddenRow">
                                  <div class="card card-body">
                                    <div class="row mt-2 mb-3">
                                      <div class="col-sm-4 on-checkbox-origin">
                                        <input
                                          formControlName="clientAddress"
                                          (change)="
                                            onClickClientAddress($event, i)
                                          "
                                          type="checkbox"
                                        />
                                        <label for="clientAddress"
                                          >Buscar no Endereço do Cliente?</label
                                        >
                                      </div>
                                      <div class="col-sm-4 on-checkbox-origin">
                                        <input
                                          type="checkbox"
                                          (change)="
                                            onClickCovenantAddress($event, i)
                                          "
                                          formControlName="covenantAddress"
                                        />
                                        <label for="covenantAddress"
                                          >Buscar no Endereço do
                                          Convênio?</label
                                        >
                                      </div>
                                      <div class="col-sm-4 on-checkbox-origin">
                                        <input
                                          formControlName="otherAddress"
                                          (change)="
                                            onClickOtherAddress($event, i)
                                          "
                                          type="checkbox"
                                        />
                                        <label for="otherAddress"
                                          >Buscar em outro Endereço?</label
                                        >
                                      </div>
                                    </div>
                                    <div formGroupName="address">
                                      <div class="row">
                                        <div class="col-sm-4 mt-1">
                                          <label>CEP</label>
                                          <input
                                            type="text"
                                            mask="00000-000"
                                            class="form-control"
                                            placeholder="CEP"
                                            (blur)="
                                              onBlurCepOriginCollaborator(
                                                $event,
                                                i
                                              )
                                            "
                                            formControlName="zipcode"
                                          />
                                        </div>
                                        <div class="col-sm-8 mt-1">
                                          <label>Logradouro</label>
                                          <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Logradouro"
                                            formControlName="publicPlace"
                                          />
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-sm-4">
                                          <label
                                            style="font-size: 14px"
                                            class="mt-2"
                                            >Bairro</label
                                          >
                                          <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Bairro"
                                            formControlName="neighborhood"
                                          />
                                        </div>
                                        <div class="col-sm-4">
                                          <label
                                            style="font-size: 14px"
                                            class="mt-2"
                                            >Estado</label
                                          >
                                          <select
                                            (change)="
                                              onSelectedStateCollaborator(
                                                $event,
                                                i
                                              )
                                            "
                                            class="form-control select2"
                                            formControlName="state"
                                          >
                                            <option value="">
                                              Selecione o Estado
                                            </option>
                                            <option
                                              value="{{ state.id }}"
                                              *ngFor="let state of states"
                                            >
                                              {{ state.name }}
                                            </option>
                                          </select>
                                        </div>
                                        <div class="col-sm-4">
                                          <label
                                            style="font-size: 14px"
                                            class="mt-2"
                                            >Cidade</label
                                          >
                                          <select
                                            class="form-control select2"
                                            formControlName="city"
                                          >
                                            <option [value]="null" disabled>
                                              Selecione a Cidade
                                            </option>
                                            <option
                                              [value]="city.id"
                                              *ngFor="let city of cities[i]"
                                            >
                                              {{ city.name }}
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr
                                class="collapse"
                                id="collapseDestinyAddress{{ i }}"
                              >
                                <td colspan="6" class="hiddenRow">
                                  <div class="card card-body">
                                    <div class="row mt-2 mb-3">
                                      <div class="col-sm-4 on-checkbox-destiny">
                                        <input
                                          formControlName="clientDestinyAddress"
                                          (change)="
                                            onClickClientDestinyAddress(
                                              $event,
                                              i
                                            )
                                          "
                                          type="checkbox"
                                        />
                                        <label for="clientDestinyAddress"
                                          >Levar no Endereço do Cliente?</label
                                        >
                                      </div>
                                      <div class="col-sm-4 on-checkbox-destiny">
                                        <input
                                          type="checkbox"
                                          (change)="
                                            onClickCovenantDestinyAddress(
                                              $event,
                                              i
                                            )
                                          "
                                          formControlName="covenantDestinyAddress"
                                        />
                                        <label for="covenantDestinyAddress"
                                          >Levar no Endereço do Convênio?</label
                                        >
                                      </div>
                                      <div class="col-sm-4 on-checkbox-destiny">
                                        <input
                                          formControlName="otherDestinyAddress"
                                          (change)="
                                            onClickOtherDestinyAddress(
                                              $event,
                                              i
                                            )
                                          "
                                          type="checkbox"
                                        />
                                        <label for="otherDestinyAddress"
                                          >Levar em outro Endereço?</label
                                        >
                                      </div>
                                    </div>
                                    <div formGroupName="destinyAddress">
                                      <div class="row">
                                        <div class="col-sm-4 mt-1">
                                          <label>CEP</label>
                                          <input
                                            type="text"
                                            mask="00000-000"
                                            class="form-control"
                                            placeholder="CEP"
                                            (blur)="
                                              onBlurCepDestinyCollaborator(
                                                $event,
                                                i
                                              )
                                            "
                                            formControlName="zipcode"
                                          />
                                        </div>
                                        <div class="col-sm-8 mt-1">
                                          <label>Logradouro</label>
                                          <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Logradouro"
                                            formControlName="publicPlace"
                                          />
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-sm-4">
                                          <label
                                            style="font-size: 14px"
                                            class="mt-2"
                                            >Bairro</label
                                          >
                                          <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Bairro"
                                            formControlName="neighborhood"
                                          />
                                        </div>
                                        <div class="col-sm-4">
                                          <label
                                            style="font-size: 14px"
                                            class="mt-2"
                                            >Estado</label
                                          >
                                          <select
                                            (change)="
                                              onSelectedStateCollaborator(
                                                $event,
                                                i
                                              )
                                            "
                                            class="form-control select2"
                                            formControlName="state"
                                          >
                                            <option [value]="null" disabled>
                                              Selecione o Estado
                                            </option>
                                            <option
                                              [value]="state.id"
                                              *ngFor="let state of states"
                                            >
                                              {{ state.name }}
                                            </option>
                                          </select>
                                        </div>
                                        <div class="col-sm-4">
                                          <label
                                            style="font-size: 14px"
                                            class="mt-2"
                                            >Cidade</label
                                          >
                                          <select
                                            class="form-control select2"
                                            formControlName="city"
                                          >
                                            <option [value]="null" disabled>
                                              Selecione a Cidade
                                            </option>
                                            <option
                                              [value]="city.id"
                                              *ngFor="
                                                let city of selectDestinyCities[
                                                  i
                                                ]
                                              "
                                            >
                                              {{ city.name }}
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        *ngIf="tripForm.controls.passenger.value == 'client'"
                      >
                        <label style="font-size: 20px" class="mt-4"
                          >Endereço de Origem</label
                        >
                        <div class="row">
                          <div class="col row mt-1 ml-2">
                            <input
                              class="mt-1"
                              type="checkbox"
                              name="otherAddress"
                              formControlName="otherAddress"
                              (change)="onCheckboxChange($event)"
                            />
                            <label class="ml-2" style="font-size: 16px"
                              >Buscar em outro Endereço?</label
                            >
                          </div>
                        </div>
                        <label>Origem</label>
                        <div formGroupName="originAddress">
                          <div class="row">
                            <div class="col-sm-4 mt-1">
                              <input
                                type="text"
                                class="form-control"
                                mask="00000-000"
                                placeholder="CEP"
                                (blur)="onBlurCepOrigin($event)"
                                formControlName="zipcode"
                              />
                              <span
                                *ngIf="
                                  tripForm.controls.originAddress.get('zipcode')
                                    .errors &&
                                  tripForm.controls.originAddress.get('zipcode')
                                    .errors.invalid
                                "
                                class="text-danger position-right"
                                >*
                                {{
                                  tripForm.controls.originAddress.get("zipcode")
                                    .errors.invalid
                                }}
                              </span>
                            </div>
                            <div class="col-sm-8 mt-1">
                              <label>Logradouro</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Logradouro"
                                formControlName="publicPlace"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4">
                              <label style="font-size: 14px" class="mt-2"
                                >Bairro</label
                              >
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Bairro"
                                formControlName="neighborhood"
                              />
                            </div>
                            <div class="col-sm-4">
                              <label style="font-size: 14px" class="mt-2"
                                >Estado</label
                              >
                              <select
                                (change)="onSelectedStateOrigin($event)"
                                class="form-control select2"
                                formControlName="state"
                              >
                                <option [value]="null" disabled>
                                  Selecione o Estado
                                </option>
                                <option
                                  [value]="state.id"
                                  *ngFor="let state of states"
                                >
                                  {{ state.name }}
                                </option>
                              </select>
                            </div>
                            <div class="col-sm-4">
                              <label style="font-size: 14px" class="mt-2"
                                >Cidade</label
                              >
                              <select
                                class="form-control select2"
                                formControlName="city"
                              >
                                <option [value]="null" disabled>
                                  Selecione a Cidade
                                </option>
                                <option
                                  [value]="city.id"
                                  *ngFor="let city of originCities"
                                >
                                  {{ city.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <label style="font-size: 20px" class="mt-4"
                          >Quantidade de Pontos</label
                        >
                        <div class="row">
                          <div class="col-sm-12">
                            <select
                              class="custom-select custom-select"
                              style="font-size: 14px"
                              formControlName="destinyPoint"
                            >
                              <option value="">Quantidade de Pontos</option>
                              <option
                                value="{{ destinyPoint.id }}"
                                *ngFor="let destinyPoint of destinyPoints"
                              >
                                {{ destinyPoint.name }}
                              </option>
                            </select>
                            <span
                              *ngIf="tripForm.controls.destinyPoint.errors"
                              class="text-danger position-right"
                              >Escolha o Quantidade de Pontos</span
                            >
                          </div>
                        </div>
                        <label style="font-size: 20px" class="mt-4"
                          >Endereço de Destino</label
                        >
                        <div class="row">
                          <div class="col row mt-1 ml-2">
                            <input
                              class="mt-1"
                              type="checkbox"
                              name="otherDestinyAddress"
                              formControlName="otherDestinyAddress"
                              (change)="onCheckboxChangeDestinyAddress($event)"
                            />
                            <label class="ml-2" style="font-size: 16px"
                              >Levar em outro Endereço?</label
                            >
                          </div>
                        </div>
                        <label>Destino</label>
                        <div formGroupName="destinyAddress">
                          <div class="row">
                            <div class="col-sm-4 mt-1">
                              <input
                                type="text"
                                class="form-control"
                                mask="00000-000"
                                placeholder="CEP"
                                (blur)="onBlurCepDestiny($event)"
                                formControlName="zipcode"
                              />
                              <span
                                *ngIf="
                                  tripForm.controls.destinyAddress.get(
                                    'zipcode'
                                  ).errors &&
                                  tripForm.controls.destinyAddress.get(
                                    'zipcode'
                                  ).errors.invalid
                                "
                                class="text-danger position-right"
                                >*
                                {{
                                  tripForm.controls.destinyAddress.get(
                                    "zipcode"
                                  ).errors.invalid
                                }}
                              </span>
                            </div>
                            <div class="col-sm-8 mt-1">
                              <label>Logradouro</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Logradouro"
                                formControlName="publicPlace"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4">
                              <label style="font-size: 14px" class="mt-2"
                                >Bairro</label
                              >
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Bairro"
                                formControlName="neighborhood"
                              />
                            </div>
                            <div class="col-sm-4">
                              <label style="font-size: 14px" class="mt-2"
                                >Estado</label
                              >
                              <select
                                (change)="onSelectedStateDestiny($event)"
                                class="form-control select2"
                                formControlName="state"
                              >
                                <option value="">Selecione o Estado</option>
                                <option
                                  value="{{ state.id }}"
                                  *ngFor="let state of states"
                                >
                                  {{ state.name }}
                                </option>
                              </select>
                            </div>
                            <div class="col-sm-4">
                              <label style="font-size: 14px" class="mt-2"
                                >Cidade</label
                              >
                              <select
                                class="form-control select2"
                                formControlName="city"
                              >
                                <option value="">Selecione a Cidade</option>
                                <option
                                  value="{{ city.id }}"
                                  *ngFor="let city of destinyCities"
                                >
                                  {{ city.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <label class="mt-3">Dados da Corrida</label>
                      <div class="row">
                        <div class="col-sm-5">
                          <label>Veículo</label>
                          <ng-select
                            class="custom-select custom-select"
                            style="font-size: 14px"
                            (change)="getDriversByCar()"
                            formControlName="car"
                            [items]="cars"
                            bindLabel="nameCarPlate"
                            bindValue="id"
                          >
                            <ng-template
                              ng-option-tmp
                              let-item="item"
                              let-index="index"
                              let-search="searchTerm"
                            >
                              <span
                                >{{ item.model.name }} -
                                {{ item.carPlate }}</span
                              >
                            </ng-template>
                          </ng-select>
                          <span
                            *ngIf="
                              tripForm.controls.car.errors &&
                              tripForm.controls.car.errors.invalid
                            "
                            class="text-danger position-right"
                            >*
                            {{ tripForm.controls.car.errors.invalid }}
                          </span>
                        </div>
                        <div class="col-sm-7">
                          <label>Motorista</label>
                          <ng-select
                            class="custom-select custom-select"
                            style="font-size: 14px"
                            formControlName="driver"
                            [items]="drivers"
                            bindLabel="name"
                            bindValue="id"
                          >
                            <ng-template
                              ng-option-tmp
                              let-item="item"
                              let-index="index"
                              let-search="searchTerm"
                            >
                              <span>{{ item.name }}</span>
                            </ng-template>
                          </ng-select>
                          <span
                            *ngIf="
                              tripForm.controls.driver.errors &&
                              tripForm.controls.driver.errors.invalid
                            "
                            class="text-danger position-right"
                            >*
                            {{ tripForm.controls.driver.errors.invalid }}
                          </span>
                        </div>
                      </div>
                      <label>Financeiro</label>
                      <div class="row">
                        <div class="col-sm-4">
                          <label>Tipo de Pagamento</label>
                          <select
                            class="custom-select custom-select"
                            formControlName="paymentMethod"
                            style="font-size: 14px"
                          >
                            <option
                              value="{{ paymentMethod.id }}"
                              *ngFor="let paymentMethod of paymentMethods"
                            >
                              {{ paymentMethod.name }}
                            </option>
                          </select>
                          <span
                            *ngIf="
                              tripForm.controls.paymentMethod.errors &&
                              tripForm.controls.paymentMethod.errors.required
                            "
                            class="text-danger position-right"
                            >*Campo Obrigatório</span
                          >
                        </div>
                        <div class="col-sm-4">
                          <label>Centro de custo</label>
                          <select
                            class="custom-select custom-select"
                            style="font-size: 14px"
                            formControlName="costcenter"
                          >
                            <option
                              [value]="costCenter.id"
                              *ngFor="let costCenter of costCenters"
                            >
                              {{ costCenter.name }}
                            </option>
                          </select>
                          <span
                            *ngIf="
                              tripForm.controls.costcenter.errors &&
                              tripForm.controls.costcenter.errors.required
                            "
                            class="text-danger position-right"
                            >*Campo Obrigatório</span
                          >
                        </div>
                        <div class="col-sm-4">
                          <div class="row">
                            <div class="col-md-12">Valor da Solicitação</div>
                            <div class="col-md-12 mt-2 ml-2">
                              {{ totalSolicitation() | currency : "BRL" }}
                            </div>
                          </div>
                        </div>

                        <!-- <div class="col-sm-4" *ngIf="tripForm.controls.passenger.value == 'client'">
                                                  <label>Valor</label>
                                                  <input type="text" class="form-control" currencyMask
                                                      [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                                                      placeholder="Valor da Corrida" formControlName="cost" />
                                                  <span
                                                      *ngIf="tripForm.controls.cost.errors && tripForm.controls.cost.errors.required"
                                                      class="text-danger position-right">*Campo Obrigatório</span>
                                              </div> -->
                      </div>
                      <div class="row">
                        <div class="col">
                          <label for="exampleFormControlTextarea1"
                            >Observação</label
                          >
                          <textarea
                            class="form-control"
                            formControlName="observation"
                            rows="5"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row my-3 d-flex justify-content-center">
                <div *ngIf="messageError.length" class="container">
                  <div class="alert alert-danger" role="alert">
                    {{ messageError }}
                  </div>
                </div>
                <div class="m-2">
                  <button class="btn btn-success" (click)="onSubmit()">
                    Salvar
                  </button>
                </div>
                <div class="m-2">
                  <a routerLink="/process/trip-request" class="btn btn-danger"
                    >Cancelar</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
